import React from "react"
import BriefStudent from "./BriefStudent"
import { useParams } from "react-router-dom"
import BriefTeacher from "./BriefTeacher"
import "./Brief.css"

const Brief = ({ user }) => {

    const { id } = useParams()
    return (
        <div className="Brief">
            {user.assistant.includes(id) ?
                <BriefTeacher user={user} />
                :
                user.rol === "STUDENT" ?
                    <BriefStudent user={user} />
                    :
                    <BriefTeacher user={user} />
                
            }
        </div>
    )
}

export default Brief