import React from "react"
import TeamStudent from "./TeamStudent"
import TeamTeacher from "./TeamTeacher"
import { useParams } from "react-router-dom"
import "./Team.css"

const Team = ({ user }) => {

    const { id } = useParams()
    return (
        <div className="Team">
            {user.assistant.includes(id) ?
                <TeamTeacher user={user} />
                :
                user.rol === "STUDENT" ?
                <TeamStudent user={user} />
                    :
                    <TeamTeacher user={user} />
                
            }
        </div>
    )
}

export default Team