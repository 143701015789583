import React, { useState, useEffect } from "react"
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import logo from "../../Assets/Img/logo.png"
import { Api } from "../../AWS/aws"
import "./Home.css"

const Home = ({ user }) => {

    const [location, setLocation] = useState("")
    const [idCourse, setIdCourse] = useState("")

    let useLocationPath = useLocation();

    const navigate = useNavigate();

    let name = "Usuario"
    if (user.name) {
            const parts = user.name.trim().split(" "); // Divide por espacios y elimina espacios extra.
        if (parts.length === 1) {
            // Si solo hay un nombre, úsalo tal cual.
            name = parts[0];
        } else if (parts.length === 2) {
            // Si hay un nombre y un apellido, úsalos tal cual.
            name = `${parts[0]} ${parts[1]}`;
        } else {
            // Si hay más de dos palabras, toma el primer nombre y el primer apellido.
            name = `${parts[0]} ${parts[parts.length - 2]}`;
        }
    }

    const getIdCourse = (id) => {
        return Api({
            path: `/project/nroadvances/${id}`
        })
    }

    useEffect(() => {
        let arrPath = useLocationPath.pathname.split("/")
        if (arrPath.length >= 3) {
            if (arrPath[1] === "project") {
                if(location === arrPath[2]){

                }else{
                    setLocation(arrPath[2])
                }
            } else {
                setLocation("")
            }
        } else {
            setLocation("")
        }
    }, [useLocationPath]);

    useEffect(() => {
        if (location !== "") {
            getIdCourse(location).then(res => {
                if (res.code === 0) {
                    setIdCourse(res.idCourse)
                }
            })
        }
    }, [location])

    return (
        <div className="Home">
            <Row className="gx-0 align-items-center nav">
                <Col className="logo">
                    <img className="img-logo" alt="Taller Diseño" src={logo} onClick={() => { navigate("/") }} />
                </Col>
                <Col className="name">
                    <h4 id="title-home">Bienvenido al {location === "" ? "Taller" : idCourse=== ""? "Taller" : <Link className="profile" to={"/course/" + idCourse}>Taller</Link>} <Link className="profile" to={"profile"}>{name}</Link> / <Link className="logout" to="/logout">SALIR</Link></h4>
                </Col>
            </Row>
            <Outlet />
        </div>
    )
}

export default Home