import React from "react"
import MoodboardStudent from "./MoodboardStudent"
import MoodboardTeacher from "./MoodboardTeacher"
import { useParams } from "react-router-dom"
import "./Moodboard.css"

const Moodboard = ({ user }) => {

    const { id } = useParams()

    return (
        <div className="Moodboard">
            {user.assistant.includes(id) ?
                <MoodboardTeacher user={user} />
                :
                user.rol === "STUDENT" ?
                <MoodboardStudent user={user} />
                    :
                    <MoodboardTeacher user={user} />
                
            }
        </div>
    )
}

export default Moodboard