import React from "react"
import AdvanceStudent from "./AdvanceStudent"
import AdvanceTeacher from "./AdvanceTeacher"
import { useParams } from "react-router-dom"
import "./Advance.css"

const Advance = ({ user }) => {

    const { id } = useParams()
    return (
        <div className="Advance">
            {user.assistant.includes(id) ?
                <AdvanceTeacher user={user} />
                :
                user.rol === "STUDENT" ?
                <AdvanceStudent user={user} />
                    :
                    <AdvanceTeacher user={user} />
                
            }
        </div>
    )
}

export default Advance